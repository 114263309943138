import React from "react";
import "./FeedItem.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Moment from "moment";

const FeedItem: React.FC<IFeedItem> = ({ id, title, leadText, link, pubDate, imageType, imageUrl, publisher }) => {
  return (
    <div className="FeedItem">
      <a href={link} target="blank">
        {title && <div className="FeedItem-title">{title}</div>}
        {imageUrl && <LazyLoadImage src={imageUrl} width="100%" className="FeedItem-image" />}
        {leadText && <div className="FeedItem-ingress">{leadText}</div>}
        <div className="FeedItem-meta">
          <div className="FeedItem-publisher">{publisher}</div>
          <div className="FeedItem-published">{Moment(pubDate).fromNow()}</div>
        </div>
      </a>
    </div>
  );
};

export default FeedItem;
