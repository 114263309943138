import Axios from "axios";
import Moment from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import "./App.css";
import FeedItem from "./components/FeedItem";
import Header from "./components/Header";
import { Settings } from "./consts";
import Loader from "./components/Loader";

Moment.locale("nb-NO");

const App: React.FC = () => {
  const [activeFeed, setActiveFeed] = useState(Settings.feeds[0]);
  const [newsItems, setNewsItems] = useState<IFeedItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadFeed();
  }, [activeFeed]);

  const loadFeed = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get(activeFeed.url);
      const items = response.data;
      setNewsItems(items);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };
  return (
    <div className="App">
      <Header setFeed={setActiveFeed} activeFeed={activeFeed} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App-feed">
          {newsItems.map(item => (
            <FeedItem key={item.id} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default App;
