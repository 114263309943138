const Colors = {
  black: "#283149",
  gray: "#404b69",
  green: "#00818a",
  lightGray: "#dbedf3"
};
// https://nyhetene-api.herokuapp.com
const Settings: ISettings = {
  feeds: [
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/0",
      name: "Hovedsaker",
      icon: "newspaper",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/1",
      name: "Teknologi",
      icon: "laptop-code",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/2",
      name: "Musikk",
      icon: "guitar",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/3",
      name: "Sport",
      icon: "basketball-ball",
      iconStyle: "solid"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/4",
      name: "Økonomi",
      icon: "money-bill-alt",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/5",
      name: "Vitenskap",
      icon: "flask",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/6",
      name: "Motor",
      icon: "truck-pickup",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/7",
      name: "Underholdning",
      icon: "tv",
      iconStyle: "regular"
    },
    {
      url: "https://nyhetene-api.herokuapp.com/api/feed/8",
      name: "Sladder",
      icon: "crown",
      iconStyle: "regular"
    }
  ]
};

export { Colors, Settings };
