import React from "react";
import "./Loader.css";

const Loader: React.FC = () => (
  <div className="Loader-outer-wrapper">
    <div className="Loader-wrapper">
      <div className="Loader-cube" />
      <div className="Loader-cube Loader-cube2" />
      <div className="Loader-cube Loader-cube4" />
      <div className="Loader-cube Loader-cube3" />
    </div>
  </div>
);

export default Loader;
