import React, { useState } from "react";
import "./Header.css";
import { Settings } from "../consts";
import cn from "classnames";

type IHeader = {
  setFeed: (url: ISettingsFeedItem) => void;
  activeFeed: ISettingsFeedItem;
};

const Header: React.FC<IHeader> = ({ setFeed, activeFeed }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const onCategoryClick = (cat: ISettingsFeedItem) => {
    setShowMenu(false);
    setFeed(cat);
  };

  return (
    <header>
      <div className="Header-full">
        <div className="Header-title" onClick={() => setFeed(Settings.feeds[0])}>
          nå.live
        </div>
        <div className="Header-categories">
          {Settings.feeds.map((cat, index) => (
            <div key={index} className={cn("Header-cat-item", { "Header-cat-item_active": cat === activeFeed })} onClick={() => setFeed(cat)}>
              {cat.name}
            </div>
          ))}
        </div>
      </div>
      <div className="Header-small">
        <div className="Header-small-top">
          <div className="Header-small-title" onClick={() => onCategoryClick(Settings.feeds[0])}>
            nå.live
          </div>
          <div className="Header-small-category">{activeFeed.name}</div>
          <div className="Header-small-toggle" onClick={toggleMenu}>
            Kategorier
          </div>
        </div>
        {showMenu && (
          <div className="Header-small-categories">
            {Settings.feeds.map((cat, index) => (
              <div
                key={index}
                className={cn("Header-small-cat-item", { "Header-small-cat-item_active": cat === activeFeed })}
                onClick={() => onCategoryClick(cat)}
              >
                {cat.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
